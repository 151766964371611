import React, { useEffect, useState } from "react";
import { AuthContext } from "./core/authContext";
import AppRouter from "./AppRouter";
import { getUserDetails } from "./core/auth";

function AppWrapper() {
  const user = getUserDetails();
  const [isLoggedIn, setIsLoggedIn] = useState(!!user);
  const [isSigningOut, setIsSigningOut] = useState(false);

  useEffect(() => {
    setIsLoggedIn(!!user);
  }, [user]);

  const userDetails = user ? JSON.parse(user) : null;

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user: userDetails, isSigningOut, setIsSigningOut }}
    >
      <AppRouter isLoggedIn={isLoggedIn} />
    </AuthContext.Provider>
  );
}

export default AppWrapper;
