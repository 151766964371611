import axios, { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import { User } from "../types/genericType";
import axiosInstance from "../core/axios";

interface UpdatePasswordParams {
  newPassword: string;
  currentPassword: string;
}

export const login = async (
  username: string,
  password: string,
  verification: string
): Promise<{ resource: User }> => {
  try {
    const response: AxiosResponse<{ resource: User }> = await axios.post(
      `${apiEndPoints.login}`,
      {
        username,
        password,
        verification,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const forgotPassword = async (username: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axios.post(
      `${apiEndPoints.forgotPassword}`,
      {
        username,
      }
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updatePassword = async ({
  newPassword,
  currentPassword,
}: UpdatePasswordParams): Promise<AxiosResponse<any>> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.credentials}`,
      {
        newPassword,
        currentPassword,
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
