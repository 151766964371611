import { createContext } from "react";
import { User } from "../types/genericType";

interface AuthContextType {
  isLoggedIn: boolean;
  isSigningOut: boolean;
  user: User | null;
  setIsSigningOut: (status: boolean) => void
}

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  isSigningOut: false,
  user: null,
  setIsSigningOut: () => {}
});
