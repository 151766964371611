import React, { useState, useEffect } from "react";
import PaidLoan from "./PaidLoan";
import ActiveLoan from "./ActiveLoan";
import DeniedLoan from "./DeniedLoan";
import PendingLoan from "./PendingLoan";
import WithdrawnLoan from "./WithdrawnLoan";
import {
  getCustomerDetails,
  getCustomerStatus,
} from "../../services/loanPortalService";
import Loader from "../../components/loader/Loader";
import { getRewardsDetails } from "../../services/rewardsService";

const LoanPortal = () => {
  const [loanStatus, setLoanStatus] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerDetails = async () => {
    try {
      setIsLoading(true);
      const customerDetails = await getCustomerDetails();
      sessionStorage.setItem(
        "customerDetails",
        JSON.stringify(customerDetails)
      );
    } catch (error) {
      console.error("Error fetching loan data:", error);
    }
  };
  
  useEffect(() => {
    const fetchLoanStatus = async () => {
      try {
        setIsLoading(true);
        const customerStatus = await getCustomerStatus();
        const rewardsDetails = await getRewardsDetails();
        sessionStorage.setItem(
          "rewardsDetails",
          JSON.stringify(rewardsDetails)
        );
        if (customerStatus) {
          const status = customerStatus?.loanStatus?.current?.status;
          setLoanStatus(status);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching loan data:", error);
      }
    };

    fetchLoanStatus();
    fetchCustomerDetails();
  }, []);

  const renderLoanContent = () => {
    switch (loanStatus) {
      case "Pending":
        return <PendingLoan />;
      case "Active":
      case "Past Due":
        return <ActiveLoan />;
      case "Paid in Full":
      case "Paid In Full Settlement":
        return <PaidLoan />;
      case "Denied":
        return <DeniedLoan />;
      case "Withdrawn":
        return <WithdrawnLoan />;
      default:
        return (
          <Loader message="Signing you into your personal Lift Credit portal..." />
        );
    }
  };

  return isLoading ? (
    <Loader message="Signing you into your personal Lift Credit portal..." />
  ) : (
    renderLoanContent()
  );
};

export default LoanPortal;
