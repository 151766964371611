import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import {
  BankDataType,
  ApiResponse,
  BankCardType,
  BankAccountType,
  NewBankAccountType,
} from "../types/genericType";

export const getBankInfo = async (routingNumber: String): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get(
      `${apiEndPoints.bankName}/${routingNumber}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const validateBankInfo = async (
  bankData: BankDataType
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `${apiEndPoints.bankInfo}`,
      bankData
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getBankCards = async () => {
  try {
    const response: AxiosResponse<ApiResponse<BankCardType>> =
      await axiosInstance.get(apiEndPoints.bankCards);
    return response?.data?.resources;
  } catch (err) {
    throw err;
  }
};

export const addBankCard = async (cardData: BankCardType) => {
  try {
    await axiosInstance.post(apiEndPoints.bankCards, cardData);
  } catch (err) {
    throw err;
  }
}

export const makePrimaryBankCard = async (cardId: string) => {
  try {
    await axiosInstance.put(`${apiEndPoints.bankCards}/${cardId}`, {
      id: cardId,
      isPrimaryBankCard: true,
    });
  } catch (err) {
    throw err;
  }
};

export const getBankAccounts = async () => {
  try {
    const response: AxiosResponse<ApiResponse<BankAccountType>> =
      await axiosInstance.get(apiEndPoints.bankAccounts);
    return response?.data?.resources;
  } catch (err) {
    throw err;
  }
};

export const addBankAccount = async (bankData: NewBankAccountType) => {
  try {
    await axiosInstance.post(apiEndPoints.bankAccounts, bankData);
  } catch (err) {
    throw err;
  }
};

export const makePrimaryBankAccount = async (accountId: string) => {
  try {
    await axiosInstance.put(`${apiEndPoints.bankAccounts}/${accountId}`, {
      id: accountId,
      isPrimaryBankAccount: true,
    });
  } catch (err) {
    throw err;
  }
};
