import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, useNavigate } from "react-router-dom";
import { CurrentLoan, LoanStatus } from "../../types/genericType";
import {
  getCurrentLoanStatus,
  getCustomerStatus,
} from "../../services/loanPortalService";
import { AuthContext } from "../../core/authContext";

const MenuComponent = () => {
  const { isLoggedIn, isSigningOut, user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = useState<null | HTMLElement>(null);
  const [loanStatus, setLoanStatus] = useState<LoanStatus | undefined>(
    undefined
  );
  const [currentLoan, setCurrentLoan] = useState<CurrentLoan>();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const location = useLocation();

  const [actionButtonText, setActionButtonText] = useState("Request New Loan");
  const [actionButtonLink, setActionButtonLink] = useState("/apply");

  useEffect(() => {
    if (isLoggedIn && !isSigningOut && user?.role === 'Applicant') {
      const fetchLoanDetails = async () => {
        try {
          const loanDetails: { loanStatus: LoanStatus } =
            await getCustomerStatus();
          if (loanDetails) {
            const status = loanDetails?.loanStatus;
            setLoanStatus(status);
          }
        } catch (error) {
          navigate("/sorry", {
            state: {
              error: {
                message: error,
                details: error,
              },
            },
          });
        }
      };

      const fetchCurrentLoanDetails = async () => {
        try {
          const currentLoanDetails = await getCurrentLoanStatus();
          if (currentLoanDetails) {
            setCurrentLoan(currentLoanDetails);
          }
        } catch (error) {
          navigate("/sorry", {
            state: {
              error: {
                message: error,
                details: error,
              },
            },
          });
        }
      };
      fetchLoanDetails();
      fetchCurrentLoanDetails();
    }
  }, [navigate, isLoggedIn, isSigningOut, user?.role]);

  useEffect(() => {
    if (loanStatus?.reloanDetails?.makePayment) {
      setActionButtonText("Make Payment");
      setActionButtonLink("/payment");
    } else if (loanStatus?.hasActiveLoan) {
      setActionButtonLink("/apply/uploan");
    } else if (loanStatus?.hasCompletedLoan) {
      setActionButtonLink("/apply/reloan");
    }

    if (location.pathname === "/rewards") {
      setActionButtonText("Cart");
      setActionButtonLink("/rewards/cart");
    } else if (location.pathname === "/rewards/cart") {
      setActionButtonText("Continue Shopping");
      setActionButtonLink("/rewards");
    } else {
      setActionButtonText("Request New Loan");
      setActionButtonLink("/apply/reloan");
    }
  }, [loanStatus, location.pathname]);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleClose();
  };

  const onActionButtonClick = () => {
    navigate(actionButtonLink);
  };

  return (
    <Container sx={{ paddingTop: "100px", backgroundColor: "#f4f4f4" }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", color: "#5a5a5b" }}
          >
            Welcome Back,
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", fontSize: "14px", color: "#5a5a5b" }}
          >
            {user?.name}
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/portal")}
          >
            Home
          </Button>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            onClick={() => navigate("/rewards")}
          >
            Redeem
          </Button>
          <Button
            sx={{
              color: "#5a5a5b",
              fontSize: "14px",
              textTransform: "capitalize",
            }}
            id="account-button"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Account
            <ExpandMoreOutlined />
          </Button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "account-button",
            }}
          >
            <MenuItem
              onClick={() => handleNavigation("/profile")}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              My Profile
            </MenuItem>
            <MenuItem
              onClick={() => handleNavigation("/loan-history")}
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
            >
              Loan History
            </MenuItem>
          </Menu>
          {loanStatus?.hasActiveLoan && (
            <>
              <Button
                sx={{
                  color: "#5a5a5b",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
                id="payment-button"
                aria-controls={open1 ? "payment-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={(event) => setAnchorEl1(event.currentTarget)}
              >
                Payments
                <ExpandMoreOutlined />
              </Button>
              <Menu
                id="payment-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={() => setAnchorEl1(null)}
                MenuListProps={{
                  "aria-labelledby": "payment-button",
                }}
              >
                {currentLoan?.enablePayment && (
                  <MenuItem
                    onClick={() => handleNavigation("/payment")}
                    sx={{ fontSize: "14px", textTransform: "capitalize" }}
                  >
                    Make a Payment
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    handleNavigation(`/loans/:${currentLoan?.id}/payments`)
                  }
                  sx={{ fontSize: "14px", textTransform: "capitalize" }}
                >
                  Payment History
                </MenuItem>
              </Menu>
            </>
          )}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#004d40",
              color: "#fff",
              fontSize: "14px",
              textTransform: "capitalize",
              ml: 2,
            }}
            onClick={onActionButtonClick}
          >
            {actionButtonText} <ChevronRightIcon />
          </Button>
        </Box>
      </Toolbar>
    </Container>
  );
};

export default MenuComponent;
