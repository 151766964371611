import { AxiosResponse } from "axios";
import axiosInstance from "../core/axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import {
  Customer,
  LoanHistoryResponse,
  PaymentHistoryResponse,
} from "../types/genericType";

export const getCustomerStatus = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.currentCustomers}/status`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getCustomerDetails = async () => {
  try {
    const response: AxiosResponse<Customer> = await axiosInstance.get(
      `${apiEndPoints.currentCustomers}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const updateCustomerDetails = async (data: Customer) => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.put(
      `${apiEndPoints.currentCustomers}`,
      data
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getLoanHistory = async (
  page: number,
  perPage: number
): Promise<LoanHistoryResponse> => {
  try {
    const response: AxiosResponse<LoanHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.loanHistory}?page=${page}&perPage=${perPage}`
      );
    return response?.data || { resources: [], count: 0 };
  } catch (err) {
    throw err;
  }
};

export const getLoanAgreement = async (format: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.agreement}/${format}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getPaymentHistory = async (
  page: number,
  perPage: number
): Promise<PaymentHistoryResponse> => {
  try {
    const response: AxiosResponse<PaymentHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.paymentHistory}?page=${page}&perPage=${perPage}`
      );
    return response?.data || { resources: [], count: 0 };
  } catch (err) {
    throw err;
  }
};

export const getCurrentLoanStatus = async () => {
  try {
    const response: AxiosResponse<any> = await axiosInstance.get(
      `${apiEndPoints.currentLoan}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
