import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Customer, LoanStatus } from "../../types/genericType";
import {
  getAdminCustomerDetails,
  getAdminCustomerLoanDetails,
} from "../../services/adminService";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const [customerID, setCustomerID] = useState<string>("");
  const [customerInfo, setCustomerInfo] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loanStatus, setLoanStatus] = useState<LoanStatus>();
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (customerID) {
      setLoading(true);
      setError(null);
      try {
        const response: Customer = await getAdminCustomerDetails({
          customerId: customerID,
        });
        setCustomerInfo(response);
        const loanResponse: LoanStatus = await getAdminCustomerLoanDetails({
          customerId: customerID,
        });
        setLoanStatus(loanResponse);
      } catch (err) {
        if (err instanceof Error && "response" in err) {
          setError((err as any).response?.data?.details);
        } else {
          setError("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const reports = {
    decisionLogic: "No Data (opt-out)",
    dataX: "No Data (opt-out)",
    clarity: "No Data (opt-out)",
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1160px",
        margin: { xs: "25px 0", sm: "25px auto" },
        padding: { xs: "0 10px", sm: "0 20px" },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              padding: { xs: "20px", sm: "30px", md: "50px 92px" },
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "#5a5a5b", mb: 1 }}>
              ADMIN
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                color: "#4caf50",
                fontWeight: "500",
                mb: 2,
              }}
            >
              Customer Admin
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              View customer loan and rewards status and approval of pending
              loans.
            </Typography>
            <TextField
              label="Customer ID"
              value={customerID}
              onChange={(e) => setCustomerID(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#004d40",
                color: "#fff",
                fontSize: "14px",
                textTransform: "capitalize",
                mt: 2,
                mb: 5,
                ":hover": {
                  backgroundColor: "#004d40",
                },
              }}
              onClick={handleSearch}
            >
              Search <ChevronRightIcon />
            </Button>
            {loading ? (
              <Box sx={{ m: 2 }}>
                {" "}
                <CircularProgress />{" "}
              </Box>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              customerInfo && (
                <>
                  <Typography variant="h6" color="#838588" fontWeight="bold">
                    Customer Info
                  </Typography>
                  <Box sx={{ mt: 2, mb: 4 }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Name:</strong>
                      <br /> {customerInfo.firstName} {customerInfo?.lastName}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Address:</strong>
                      <br />
                      {customerInfo.address?.addressLine1}{" "}
                      {customerInfo.address?.addressLine2} <br />{" "}
                      {customerInfo.address?.city} {","}{" "}
                      {customerInfo.address?.state} {","}{" "}
                      {customerInfo.address?.zip}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>DOB:</strong>
                      <br />{" "}
                      {new Date(customerInfo.dateOfBirth).toLocaleDateString(
                        "en-GB",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>SSN:</strong> <br />
                      {customerInfo.socialSecurityNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Email:</strong> <br />
                      {customerInfo.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588" }}
                    >
                      <strong>Phone:</strong> <br />
                      {customerInfo.phoneNumber}
                    </Typography>
                  </Box>

                  <Typography variant="h6" color="#838588" fontWeight="bold">
                    Loan Status
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ mt: 2, mb: 4, color: "#838588" }}
                  >
                    {loanStatus?.hasPendingLoan
                      ? "Customer has active loan"
                      : "Customer has no pending loan"}
                  </Typography>
                </>
              )
            )}
          </Box>
        </Grid>

        {customerInfo && (
          <Grid item xs={12} md={4}>
            <Box sx={{ padding: "24px" }}>
              {reports &&
                Object.entries(reports).map(([key, value]) => (
                  <Box key={key} sx={{ mb: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, mb: 1, color: "#838588", lineHeight: "2" }}
                    >
                      <strong>
                        {" "}
                        {`${key.charAt(0).toUpperCase() + key.slice(1)} Report`}
                      </strong>{" "}
                      <br />
                      {value as string}
                    </Typography>
                  </Box>
                ))}
              <Typography
                variant="body2"
                sx={{ mt: 1, color: "#838588", lineHeight: "2" }}
              >
                <strong>Rewards History</strong> <br />
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: "#4caf50",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "rgba(25, 118, 210, 0.1)",
                  },
                }}
                onClick={() => {
                  navigate(`/admin/rewards/${customerID}`);
                }}
              >
                View
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Admin;
