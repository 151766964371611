import React from "react";
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";

type TextInputProps = TextFieldProps & {
  label: string;
  value: string;
  readOnly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  endAdornmentText?: string;
  endAdornmentIcon?: React.ReactNode;
};

const TextInput: React.FC<TextInputProps> = ({
  label,
  value,
  readOnly = true,
  onChange,
  error,
  helperText,
  endAdornmentText,
  endAdornmentIcon,
  ...rest
}) => {
  return readOnly ? (
    <TextField
      label={label}
      value={value}
      InputProps={{
        readOnly,
        sx: {
          fontSize: "14px",
          fontWeight: "normal",
          color: "grey",
          pt: 1
        },
        endAdornment:
          endAdornmentText || endAdornmentIcon ? (
            <InputAdornment position="end" sx={{ color: "grey", fontSize: 12 }}>
              {endAdornmentIcon}
              {endAdornmentText}
            </InputAdornment>
          ) : undefined,
      }}
      InputLabelProps={{
        sx: {
          fontSize: "16px",
          fontWeight: "bold",
        },
      }}
      fullWidth
      variant="standard"
      size="small"
      {...rest}
    />
  ) : (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: "12px",
          fontWeight: "600",
          color: "#838588",
        }}
      >
        {label}
      </Typography>
      <TextField
        value={value}
        onChange={onChange}
        fullWidth
        size="small"
        variant="outlined"
        placeholder={rest?.placeholder}
        error={error}
        helperText={helperText}
      />
    </>
  );
};

export default TextInput;
