import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

type ApiSuccessPropsType = {
  message: string;
  details?: string;
  onClose: any;
  detailsRoute?: string;
};

const ApiSuccess = (props: ApiSuccessPropsType) => {
  const { message, details, onClose, detailsRoute } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    if (detailsRoute) {
      navigate(detailsRoute, {
        state: {
          error: {
            message: message,
            details: details,
          },
        },
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#dff0d8",
        padding: "10px 15px",
        margin: "20px 0",
        borderRadius: "5px",
        color: "#3c763d",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Typography sx={{ fontSize: "14px" }}>
        {message}
        {detailsRoute && (
          <span style={{ textDecoration: "underline", color: "#3c763d" }}>
            view details
          </span>
        )}
      </Typography>
      <CloseIcon
        sx={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      />
    </Box>
  );
};

export default ApiSuccess;
