import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  Container,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  addBankCard,
  getBankCards,
  makePrimaryBankCard,
} from "../../services/bankService";
import { BankCardType } from "../../types/genericType";
import {
  AddOutlined,
  StarBorderOutlined,
  StarOutlined,
} from "@mui/icons-material";
import TextInput from "../common/TextInput";
import AddressForm from "../addressForm/AddressForm";

const BankCard = () => {
  const [bankCards, setBankCards] = useState<BankCardType[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState<any>({
    nameOnCard: "",
    cardNumber: "",
    securityCode: "",
    expiration: "",
    billingAddressSameAsPrimary: true,
  });

  const [errors, setErrors] = useState({
    nameOnCard: "",
    cardNumber: "",
    billingAddress: "",
    securityCode: "",
    expiration: "",
  });

  useEffect(() => {
    fetchBankCards();
  }, []);

  useEffect(() => {
    if (formData.billingAddressSameAsPrimary) {
      setFormData((prev: { [x: string]: any; billingAddress: any }) => {
        const { billingAddress, ...rest } = prev;
        return { ...rest };
      });
    }
  }, [bankCards, formData.billingAddressSameAsPrimary]);

  const validate = () => {
    let hasError = false;
    const newErrors: {
      nameOnCard: string;
      cardNumber: string;
      billingAddress: string;
      securityCode: string;
      expiration: string;
    } = {
      nameOnCard: "",
      cardNumber: "",
      billingAddress: "",
      securityCode: "",
      expiration: "",
    };

    if (!formData.nameOnCard || formData.nameOnCard.trim() === "") {
      newErrors.nameOnCard = "Required field";
      hasError = true;
    }

    if (!formData.cardNumber || formData.cardNumber.trim() === "") {
      newErrors.cardNumber = "Required field";
      hasError = true;
    } else if (!/^\d{16}$/.test(formData.cardNumber.replace(/-/g, ""))) {
      newErrors.cardNumber = "Card number must be 16 digits.";
      hasError = true;
    }

    if (!formData.expiration || formData.expiration.trim() === "") {
      newErrors.expiration = "Expiration Date is required.";
      hasError = true;
    } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(formData.expiration)) {
      newErrors.expiration = "Invalid expiration date.";
      hasError = true;
    }

    if (!formData.securityCode || formData.securityCode.trim() === "") {
      newErrors.securityCode = "Required field";
      hasError = true;
    } else if (!/^\d{4,6}$/.test(formData.securityCode)) {
      newErrors.securityCode = "Security code must be between 4 to 6 digits.";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  };

  const fetchBankCards = async () => {
    try {
      const cards = await getBankCards();
      setBankCards(cards);
    } catch (error) {
      console.error("Failed to fetch debit cards", error);
    } 
  };

  const handleAddCard = async () => {
    try {
      await addBankCard(formData);
      fetchBankCards();
    } catch (error) {
      console.error("Failed to add debit card", error);
    }
  };

  const handleMakePrimary = async (cardId: number) => {
    try {
      await makePrimaryBankCard(cardId.toString());
      fetchBankCards();
    } catch (error) {
      console.error("Failed to make card primary", error);
    }
  };

  const handleCardChange = (field: string, value: string) => {
    let formattedValue = value;

    if (field === "cardNumber") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{4})(?=\d)/g, "$1-")
        .substring(0, 19);
    } else if (field === "expiration") {
      formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{2})(?=\d)/g, "$1/")
        .substring(0, 5);
    } else if (field === "securityCode") {
      formattedValue = value.replace(/\D/g, "").substring(0, 4);
    }

    setFormData({ ...formData, [field]: formattedValue });
    setErrors({ ...errors, [field]: "" });
  };

  const handleCancel = () => {
    setFormData({
      nameOnCard: "",
      cardNumber: "",
      billingAddress: {
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
      },
      expiration: "",
      securityCode: "",
      billingAddressSameAsPrimary: true,
    });
    setErrors({
      nameOnCard: "",
      cardNumber: "",
      billingAddress: "",
      securityCode: "",
      expiration: "",
    });
    setEditMode(false);
  };

  const handleSave = async () => {
    if (validate()) {
      await handleAddCard();
      handleCancel();
    }
  };

  const handleAddressChange = ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    setFormData({
      ...formData,
      billingAddress: { ...formData?.billingAddress, [id]: value },
    });
  };

  return (
    <Box mt={3} sx={{width: "100%"}}>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, color: "#838588" }}>
            Debit Card Information
          </Typography>
          {!editMode && (
            <Button
              variant="text"
              sx={{
                color: "#00db8f",
                textTransform: "none",
                alignItems: "center",
                mb: 2,
              }}
              onClick={() => setEditMode(true)}
            >
              <AddOutlined sx={{ fontSize: "18px", color: "#00db8f" }} />
              Add Debit Card
            </Button>
          )}
        </Box>
        {bankCards.map((card) => (
          <Grid container spacing={2} key={card.id}>
            {!card.isPrimaryBankCard && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  mt: 5,
                  width: "100%",
                  mb: -4,
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "#00db8f",
                    textTransform: "none",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    card.id !== undefined && handleMakePrimary(card.id)
                  }
                >
                  <StarBorderOutlined
                    sx={{ fontSize: "18px", color: "#00db8f" }}
                  />
                  Make Primary
                </Button>
              </Box>
            )}
            <Grid item xs={12}>
              <TextInput
                label="Name on Card"
                value={card.nameOnCard}
                readOnly
                endAdornmentIcon={card.isPrimaryBankCard && <StarOutlined />}
                endAdornmentText={card.isPrimaryBankCard ? "Primary Card" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput label="Card Number" value={card.cardNumber} readOnly />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Billing Address"
                value={`${card?.billingAddress?.addressLine1 || ""} ${
                  card?.billingAddress?.city || ""
                } ${card?.billingAddress?.state || ""} ${
                  card?.billingAddress?.zip || ""
                }`}
                readOnly
              />
            </Grid>
          </Grid>
        ))}
        {editMode && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Name on Card
              </Typography>
              <TextField
                variant="outlined"
                value={formData.nameOnCard}
                onChange={(e) => handleCardChange("nameOnCard", e.target.value)}
                fullWidth
                size="small"
                placeholder="Enter Card Holder Name"
                error={!!errors.nameOnCard}
                helperText={errors.nameOnCard}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Card Number
              </Typography>
              <TextField
                variant="outlined"
                value={formData.cardNumber}
                onChange={(e) => handleCardChange("cardNumber", e.target.value)}
                fullWidth
                size="small"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                error={!!errors.cardNumber}
                helperText={errors.cardNumber}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Expiration
              </Typography>
              <TextField
                variant="outlined"
                value={formData.expiration}
                onChange={(e) => handleCardChange("expiration", e.target.value)}
                fullWidth
                size="small"
                placeholder="MM/YY"
                error={!!errors.expiration}
                helperText={errors.expiration}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  paddingLeft: "8px",
                  marginBottom: "5px",
                  color: "#838588",
                }}
              >
                Security Code
              </Typography>
              <TextField
                variant="outlined"
                value={formData.securityCode}
                onChange={(e) =>
                  handleCardChange("securityCode", e.target.value)
                }
                fullWidth
                size="small"
                placeholder="CVV"
                error={!!errors.securityCode}
                helperText={errors.securityCode}
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                value={formData.billingAddressSameAsPrimary}
                onChange={(e) =>
                  setFormData((prev: any) => ({
                    ...prev,
                    billingAddressSameAsPrimary: e.target.value === "true",
                  }))
                }
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <FormControlLabel
                  value={true}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: "12px",
                        color: "#838588",
                        marginBottom: "20px",
                      }}
                    >
                      Yes, my billing address is the same as my mailing address.
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  control={
                    <Radio
                      sx={{
                        color: "#00db8f",
                        padding: "0px 9px !important",
                        "&.Mui-checked": {
                          color: "#00db8f",
                          borderRadius: "50%",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "12px", color: "#838588" }}
                    >
                      No, my billing address is not the same as my mailing
                      address.
                    </Typography>
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
        {!formData.billingAddressSameAsPrimary && (
          <Box mt={4}>
            <Grid container spacing={2}>
              <AddressForm
                value={formData?.billingAddress}
                onChange={handleAddressChange}
                required
                addressRequired
                addressLabel="Billing Address"
              />
            </Grid>
          </Box>
        )}
        <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
          {editMode && (
            <>
              <Button
                variant="text"
                sx={{
                  color: "#838588",
                  textTransform: "none",
                  marginRight: 2,
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#00db8f",
                }}
                onClick={handleSave}
              >
                Save
              </Button>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default BankCard;
