import React from 'react'
import MyProfile from '../MyProfile/MyProfile'

type Props = {}

const Step2 = (props: Props) => {
  return (
    <MyProfile />
  )
}

export default Step2