import React from 'react'

type Props = {}

const Step3 = (props: Props) => {
  return (
    <div>Step3</div>
  )
}

export default Step3