import { AxiosResponse } from "axios";
import { apiEndPoints } from "../constants/apiEndpoints";
import axiosInstance from "../core/axios";
import {
  Customer,
  LoanStatus,
  RewardHistoryResponse,
  RewardSummary,
} from "../types/genericType";

export const getAdminCustomerDetails = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<Customer> = await axiosInstance.get(
      `${apiEndPoints.adminCustomerDetails}/${customerId}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getAdminCustomerLoanDetails = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<LoanStatus> = await axiosInstance.get(
      `${apiEndPoints.adminCustomerDetails}/${customerId}/loans/status`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsSummary = async ({
  customerId,
}: {
  customerId: string;
}) => {
  try {
    const response: AxiosResponse<RewardSummary> = await axiosInstance.get(
      `${apiEndPoints.adminRewardSummary}/${customerId}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const getRewardsTransactionHistory = async ({
  customerId,
  page,
  perPage,
}: {
  customerId: string;
  page: number;
  perPage: number;
}) => {
  try {
    const response: AxiosResponse<RewardHistoryResponse> =
      await axiosInstance.get(
        `${apiEndPoints.adminRewardTransactions}/${customerId}?page=${page}&perPage=${perPage}`
      );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
