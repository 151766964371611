export const apiEndPoints = {
    address: "/api/addresses",
    leads: "/api/leads",
    bankName: "/api/bankNames",
    bankInfo: "/api/validations/bankInfo",
    loanApplications: "/api/loanApplications",
    login: "/api/authorizations",
    forgotPassword: "/api/credentials/reset",
    currentCustomers: "/api/customers/current",
    loanHistory: "/api/loans/history",
    rewards: "/api/rewards",
    summary: "/api/rewards/orders/summary",
    disclaimers: "/api/rewards/disclaimers",
    order: "/api/rewards/orders",
    rewardSummary: "/api/rewards/summary",
    agreement: "/api/loans/current/agreement",
    paymentHistory: "/api/loans/current/payments",
    currentLoan: "/api/loans/current",
    adminRewardTransactions: "/api/admin/rewards/transactions",
    adminRewardSummary: "/api/admin/rewards/summary",
    adminCustomerDetails: "/api/admin/customers",
    credentials: "/api/credentials",
    bankCards: "/api/bankcards",
    bankAccounts: "/api/bankaccounts",
  };
  