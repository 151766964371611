import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { Payment, PaymentHistoryResponse } from "../../types/genericType";
import { getPaymentHistory } from "../../services/loanPortalService";
import { useParams } from "react-router-dom";

const PaymentHistory = () => {
  const { loanId } = useParams();
  const [payments, setpayments] = useState<Payment[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalpayments, setTotalpayments] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPayments = async (page: number, perPage: number) => {
    setLoading(true);
    setError(null);
    try {
      const response: PaymentHistoryResponse = await getPaymentHistory(
        page,
        perPage
      );
      setpayments(response.resources);
      setTotalpayments(response.count || 0);
    } catch (err) {
      setError("Failed to load loan data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableHeaders = useMemo(
    () => [
      "Date",
      "Amount",
      "Principal Paid",
      "Interest Paid",
      "Back Interest Paid",
      "Fees Paid",
      "Principal Balance",
    ],
    []
  );

  const headerStyle = { backgroundColor: "lightgray", color: "#36454F" };
  const rowStyle = {
    backgroundColor: "#F5F5F5",
    color: "#36454F",
    align: "center",
  };

  return (
    <div>
      <Typography sx={{ fontSize: "12px", color: "#5a5a5b", mb: 2 }}>
        Payments
      </Typography>
      <Typography sx={{ fontSize: "20px", color: "#4caf50", mb: 4 }}>
        Payment History
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="loan history table">
              <TableHead sx={{ backgroundColor: "grey" }}>
                <TableCell colSpan={7} sx={{ color: "white", fontSize: "16px" }}>
                  Loan {loanId}
                </TableCell>
              </TableHead>

              <TableHead sx={{ ...headerStyle }}>
                <TableRow>
                  {tableHeaders.map((col) => (
                    <TableCell key={col} sx={{ color: headerStyle.color }}>
                      {col}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment) => (
                  <TableRow key={payment.id} sx={{ ...rowStyle }}>
                    <TableCell sx={{width: "80px"}}>
                      {new Date(payment.timestamp).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                      })}
                    </TableCell>
                    <TableCell>US${payment.amount.toFixed(2)}</TableCell>
                    <TableCell>US${payment.principalPaid.toFixed(2)}</TableCell>
                    <TableCell>US${payment.interestPaid.toFixed(2)}</TableCell>
                    <TableCell>
                      US${payment.backInterestPaid.toFixed(2)}
                    </TableCell>
                    <TableCell>US${payment.feesPaid.toFixed(2)}</TableCell>
                    <TableCell>
                      US${payment.principalBalance.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalpayments}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      )}
    </div>
  );
};

export default PaymentHistory;
