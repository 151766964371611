import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  Customer,
  RewardHistoryResponse,
  RewardSummary,
  RewardTransaction,
} from "../../types/genericType";
import {
  getAdminCustomerDetails,
  getRewardsSummary,
  getRewardsTransactionHistory,
} from "../../services/adminService";
import { useParams } from "react-router-dom";

const RewardHistory = () => {
  const { customerId } = useParams();
  const [rewards, setRewards] = useState<RewardTransaction[]>([]);
  const [rewardSummary, setRewardSummary] = useState<RewardSummary>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [customerInfo, setCustomerInfo] = useState<any | null>(null);

  const fetchRewardHistory = async (page: number, rowsPerPage: number) => {
    setLoading(true);
    setError(null);
    if (customerId) {
      try {
        const response: RewardHistoryResponse =
          await getRewardsTransactionHistory({
            customerId: customerId,
            page: page,
            perPage: rowsPerPage,
          });
        setRewards(response.resources);
        const summaryResponse: RewardSummary = await getRewardsSummary({
          customerId: customerId,
        });
        setRewardSummary(summaryResponse);
        const customerResponse: Customer = await getAdminCustomerDetails({
          customerId: customerId,
        });
        setCustomerInfo(customerResponse);
      } catch (err) {
        setError("Failed to load loan data");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchRewardHistory(0, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const tableHeaders = useMemo(
    () => ["Date", "Type", "Details", "Description", "Points"],
    []
  );

  const headerStyle = { backgroundColor: "lightgray", color: "#36454F" };
  const rowStyle = {
    backgroundColor: "#F5F5F5",
    color: "#36454F",
    align: "center",
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1160px",
        margin: { xs: "25px 0", sm: "25px auto" },
        padding: { xs: "0 10px", sm: "0 20px" },
      }}
    >
      <Box
        sx={{
          padding: { xs: "20px", sm: "30px", md: "50px 92px" },
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 1,
        }}
      >
        <Typography sx={{ fontSize: "14px", color: "#5a5a5b" }}>
          ADMIN
        </Typography>
        <Typography
          sx={{ fontSize: "24px", color: "#4caf50", mb: 4, fontWeight: "500" }}
        >
          Customer Rewards
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#5a5a5b", mb: 2 }}>
          Customer ID: {customerId} <br />
          Customer Name: {customerInfo?.firstName} {customerInfo?.lastName}
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#5a5a5b",
            fontWeight: 500,
            mt: 4,
          }}
        >
          Total Points: {rewardSummary?.pointAmount}
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#5a5a5b", mb: 3 }}>
          Qualifying Payments: {rewardSummary?.qualifyingPayments}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="loan history table">
                <TableHead sx={{ backgroundColor: "grey" }}>
                  <TableCell colSpan={6} sx={{ color: "white" }}>
                    Rewards History
                  </TableCell>
                </TableHead>
                <TableHead sx={{ ...headerStyle }}>
                  <TableRow>
                    {tableHeaders.map((col) => (
                      <TableCell key={col} sx={{ color: headerStyle.color }}>
                        {col}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rewards.map((reward) => (
                    <TableRow key={reward.id} sx={{ ...rowStyle }}>
                      <TableCell>
                        {new Date(reward.date).toLocaleDateString("en-GB", {
                          day: "numeric",
                          month: "short",
                        })}
                      </TableCell>
                      <TableCell>{reward.type}</TableCell>
                      <TableCell>{reward.details}</TableCell>
                      <TableCell>{reward.description}</TableCell>
                      <TableCell>{reward.points}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>{" "}
            {rewards.length === 0 && <Box sx={{ p: 2 }}>No items</Box>}
          </>
        )}
      </Box>
    </Box>
  );
};

export default RewardHistory;
